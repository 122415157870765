import { ClassNames } from "@rocketmakers/armstrong-edge";
import { graphql, PageProps } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as React from "react";
import Shell from "../layout/shell";

import * as styles from "./my-story.module.scss";

const MyStory: React.FC<PageProps<Queries.MyStoryPageQuery>> = (pageProps) => {
  const data = pageProps.data.myStoryPage?.data;

  if (!data) {
    return null;
  }

  const hero = data.hero && getImage(data.hero?.gatsbyImageData);

  return (
    <Shell>
      <div className={ClassNames.concat("fixed-width", styles.myStoryView)}>
        <h2>{data.main_copy_intro}</h2>
        {hero && (
          <div className={styles.hero}>
            <GatsbyImage image={hero} alt={data.hero.alt || ""} />
            <label>{data.hero.alt}</label>
          </div>
        )}
        <div className={styles.copyBlocks}>
          <div className={styles.block}>
            <h3>{data.main_copy_title}</h3>
            <p className={styles.copy}>{data.main_copy}</p>
          </div>
          <div>
            {data.right_copy_blocks?.map((block) => (
              <div key={block?.right_copy_title} className={styles.block}>
                <h3>{block?.right_copy_title}</h3>
                <p className={styles.intro}>{block?.right_copy_subtitle}</p>
                <p className={styles.mini}>{block?.right_copy}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Shell>
  );
};

export const query = graphql`
  query MyStoryPage {
    myStoryPage: prismicMyStoryPage {
      data {
        title
        hero {
          gatsbyImageData
          alt
        }
        main_copy_title
        main_copy_intro
        main_copy
        right_copy_blocks {
          right_copy
          right_copy_subtitle
          right_copy_title
        }
      }
    }
  }
`;

export default MyStory;
